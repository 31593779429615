/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

const jQuery = require('jquery');
const $ = jQuery;
import Cropper from 'cropperjs/src/js/cropper.js';
// import AOS from 'aos';
//
// AOS.init({
//  duration: 800,
//  easing: 'slide',
//  once: false
// });


require('jquery-ui');
require('bootstrap');
require('bootstrap-datepicker');
require('../js/analytics.js');

require('../fontawesome-pro-5.12.0-web/css/all.css');
// require('owlcarousel/owl-carousel/owl.carousel.js');
// require('fancybox');
// require('easing');
// require('jquery.countdown/jquery.countdown.js');
// require('jquery.sticky/src/jquery.sticky.js');
// require('../js/jquery.easing.1.3.js');
// require('../js/jquery.sticky.js');
// require('../js/jquery.countdown.min.js');
// require('../js/template.js');



// any CSS you import will output into a single css file (app.css in this case)
import 'bootstrap/dist/css/bootstrap.css';
// import 'aos/dist/aos.css';
// import 'fancybox/dist/css/jquery.fancybox.css';
// import 'owlcarousel/owl-carousel/owl.carousel.css';
// import 'owlcarousel/owl-carousel/owl.theme.css';
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker3.css';

import 'jquery-ui/themes/base/all.css';

require('cropperjs/dist/cropper.css');
// import '../fonts/icomoon/style.css';
// import '../fonts/flaticon/font/flaticon.css';
// import '../css/template.css';
import '../css/app.css';
import '../css/shop.css';
import '../css/lp.css';
// 
// $.fn.extend({
//   toggleText: function(a, b) {
//     return this.text(this.text() == b ? a : b);
//   }
// });
//
// $('input[type=radio][name=switch-weeks-months]').change(function() {
//
//   $('.analytics-by-weeks').toggleClass('d-none', '');
//   $('.analytics-by-months').toggleClass('d-none', '');
//
// });
//
// $('.show-more-entries').click(function() {
//   $(this).find('span').toggleText('Показать еще ', 'Скрыть ');
//   $(this).closest('table').find('tr.more-entries').toggleClass('d-none', '');
// });

// Пересчет общей суммы товаров в корзине
function calcItogo(basketarray) {
  var itogo = 0;
  if ($('.basket').length > 0) {
    basketarray.forEach(function(item) {
      var tovarcount = document.querySelector('input.count41[tovar-id="' + item.tovar + '"]').value
      var tovarcost = parseInt(document.querySelector('.basket-cost-one[tovar-id="' + item.tovar + '"]').innerHTML);
      itogo = itogo + tovarcost * tovarcount;

      document.querySelector('.basket-cost-total[tovar-id="' + item.tovar + '"]').innerHTML = tovarcost * tovarcount;
      // tovarcount + 'шт. = ' + (tovarcost * tovarcount)

    });
    document.querySelector(".order-price-cost").innerHTML = itogo;
  }
}

// Пересчет общей суммы товаров на странице заказа
function calcItogoOrder(basketarray) {

  if ($('.order').length > 0) {
    var itogo = 0;
    basketarray.forEach(function(item) {
      var tovarcount = document.querySelector('.order span.count41[tovar-id="' + item.tovar + '"]').innerHTML
      var tovarcost = parseInt(document.querySelector('.order .basket-cost-one[tovar-id="' + item.tovar + '"]').innerHTML);
      itogo = itogo + tovarcost * tovarcount;
      document.querySelector('.order .basket-cost-total[tovar-id="' + item.tovar + '"]').innerHTML = tovarcost * tovarcount;
      // tovarcount + 'шт. = ' + (tovarcost * tovarcount)
    });
    document.querySelector(".order .order-price-cost").innerHTML = itogo;
  }
}

function changeTovarCountLS(elem, currentcount) {
  var id = parseInt($(elem).closest('.basket-row').find('.basket-cost-one').attr('tovar-id'))
  var basket_category = $('.breadcrumbs').attr('category');
  var basketstorage = localStorage.getItem(basket_category)
  var basketarray = JSON.parse(basketstorage);
  var newarray = [];
  basketarray.forEach(function(item) {
    if (parseInt(item.tovar) == id) {
      newarray.push({
        tovar: id,
        count: currentcount
      })
    } else {
      newarray.push(item)
    }
  });

  var basketjson = JSON.stringify(newarray);
  localStorage.setItem(basket_category, basketjson);
  calcItogo(basketarray);
  calcItogoOrder(basketarray);
}

function autoPlayYouTubeModal() {
  var triggerOpen = $("body").find('[data-tagVideo]');
  triggerOpen.click(function() {
    var theModal = $(this).data("bs-target"),
      videoSRC = $(this).attr("data-tagVideo"),
      videoSRCauto = videoSRC + "?autoplay=0",
      videoTitle = $(this).attr("data-tagVideoTitle");

    if (theModal == '#youtubeModal') {
      // $(theModal + ' .form-header').text();
      videoTitle = 'Записаться на консультацию по теме "' + videoTitle + '"'
    }

    // form-header text-center mb-4
    $(theModal + ' iframe').attr('src', videoSRCauto);
    $(theModal + ' #youtubeModalLabel').text(videoTitle);
    $(theModal + ' button.btn-close').click(function() {
      $(theModal + ' iframe').attr('src', videoSRC);
      $(theModal + ' #youtubeModalLabel').text(' ');
    });
  });
}

$(document).ready(function() {

  autoPlayYouTubeModal();

  $('.read-more-advantages a').click(function() {
    $('.read-more-advantages a').addClass('d-none');
    $('.advantages ul.d-none').removeClass('d-none');
  })
  $('.userdeliverytype').on('change', function(ev) {
    var novaposhta = $('#novaposhta').is(':checked');
    var samovivoz = $('#samovivoz').is(':checked');

    if (novaposhta == true) {
      $('.deliveryfield').removeClass('d-none');

    }

    if (samovivoz == true) {
      $('.deliveryfield').addClass('d-none');
      $('.order .usercity').attr('value', '');
      $('.order .usernewpostid').attr('value', '');
    }
  });

  $('.btn-order-finish').click(function() {


    var category = $('.breadcrumbs').attr('category');
    var username = $('.order .username').val();
    var userphone = $('.order .userphone').val();
    var useremail = $('.order .useremail').val();
    var usercity = $('.order .usercity').val();
    var usernewpostid = $('.order .usernewpostid').val();
    var userdeliverytype = $('.order .userdeliverytype:checked').val();
    var ordercomment = $('.order .ordercomment').val();
    var tovars = localStorage.getItem(category);

    var errors = [];

    // Валидация имени
    if (username.length < 3) {
      errors.push('Введите имя');
    }

    // Валидация телефонов
    if (userphone.length < 6) {
      errors.push('Не верно введен номер телефона');
    }
    // var re_start = /^\+380/i;
    // if (userphone.match(re_start)) {
    //   var re = /\+380\d{9}$/i;
    //   if (!userphone.match(re)) {
    //     errors.push('Не верно введен номер телефона');
    //   }
    // } else {
    //   if (userphone.length < 6) {
    //     errors.push('Не верно введен номер телефона');
    //   }
    // }



    // Валидация email
    var email_pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/i;
    if (useremail.search(email_pattern) !== 0) {
      errors.push('Не верно введен email');
    }


    if (category == 'fermerskie-produkty' && userdeliverytype == 0) {
      if (usercity.length == 0) {
        errors.push('Введите город доставки');
      }

      if (usernewpostid.length == 0) {
        errors.push('Введите номер отделения новой почты');
      }
    }




    // console.log(category);
    // console.log(username);
    // console.log(userphone);
    // console.log(useremail);
    // console.log(usercity);
    // console.log(usernewpostid);
    // console.log(userdeliverytype);
    // console.log(ordercomment);
    // console.log(tovars);

    $('.errors-place ul').html(' ');
    if (errors.length > 0) {
      $('.errors-place').removeClass('d-none');
      errors.forEach(function(error) {
        $('.errors-place ul').append('<li>' + error + '</li>');
      });
    } else {
      $('.errors-place').addClass('d-none');

      var order = {};
      order.category = category;
      order.username = username;
      order.userphone = userphone;
      order.useremail = useremail;
      order.usercity = usercity;
      order.usernewpostid = usernewpostid;
      order.userdeliverytype = userdeliverytype;
      order.ordercomment = ordercomment;
      order.tovars_json = tovars;

      var order_json = JSON.stringify(order);
      // console.log(order_json);
      $('.btn-order-finish').attr('disabled', 'disabled');
      $('.btn-order-finish').addClass('d-none');
      $('.preloader').removeClass('d-none');
      localStorage.removeItem(category);
      var url = "https://second.ivf-genesis-dnepr.ua/api/new-basket-order/";
      $.post(url, order_json, function(resp) {
        window.location.href = 'https://second.ivf-genesis-dnepr.ua/api/pay/' + resp;
      });
    }



  })



  jQuery(document).on('click', '.cntbtnplus', function() {
    var currentcount = parseInt($(this).parent().find('.count').val());
    var id = parseInt($(this).closest('.basket-row').find('.basket-cost-one').attr('tovar-id'));

    currentcount = currentcount + 1;
    $('input.count41[tovar-id="' + id + '"]').val(currentcount);
    $('span.count41[tovar-id="' + id + '"]').html(currentcount);
    // $(this).parent().find('.count').val(currentcount);

    var tovarcost = parseInt($(this).closest('.basket-row').find('.basket-cost-one').html());

    $('.basket-cost-total[tovar-id="' + id + '"]').html(tovarcost * currentcount);
    // currentcount + "шт. = " + (tovarcost * currentcount)

    changeTovarCountLS(this, currentcount);
  });

  jQuery(document).on('click', '.cntbtnminus', function() {
    var currentcount = parseInt($(this).parent().find('.count').val());
    var id = parseInt($(this).closest('.basket-row').find('.basket-cost-one').attr('tovar-id'));

    if (currentcount > 1) {
      currentcount = currentcount - 1;

      $('input.count41[tovar-id="' + id + '"]').val(currentcount);
      $('span.count41[tovar-id="' + id + '"]').html(currentcount);

      // $(this).parent().find('.count').val(currentcount);

      var tovarcost = parseInt($(this).closest('.basket-row').find('.basket-cost-one').html());
      $('.basket-cost-total[tovar-id="' + id + '"]').html(tovarcost * currentcount);

      // currentcount + "шт. = " + (tovarcost * currentcount)

      changeTovarCountLS(this, currentcount);
    }

  });

  // При удалении товара из корзины
  jQuery(document).on('click', '.remove-tovar-basket', function() {


    var id = parseInt($(this).attr('tovar-id'));

    $('.basket-row[tovar-id="' + id + '"]').fadeOut(500);

    var basket_category = $('.breadcrumbs').attr('category');
    var basketstorage = localStorage.getItem(basket_category)
    if (basketstorage != null) {
      var newbasketarray = [];
      var basketarray = JSON.parse(basketstorage);
      basketarray.forEach(function(item) {
        if (item.tovar != id) {
          newbasketarray.push(item)
        }
      });
      var basketjson = JSON.stringify(newbasketarray);
      localStorage.setItem(basket_category, basketjson);

      $('button.addtobasket[tovar-id="' + id + '"]').attr('disabled', false);
      $('button.addtobasket[tovar-id="' + id + '"]').removeClass('btn-secondary').addClass('btn-primary').addClass('btn-primary-gradient');
      $('button.addtobasket[tovar-id="' + id + '"]').html('В корзину');


      // в кнопке корзина не выводим 0 тов.
      $('#basketlen').removeClass('d-none');
      if (newbasketarray.length == 0) {
        $('#basketlen').addClass('d-none');
      }

      // в кнопке корзина выводим кол-во товаров
      $('#countinbasket').html(newbasketarray.length);

      if (newbasketarray.length == 0) {
        $("#modal-body-basket .modal-body").html("<div class='mt-5 mb-5 h3 text-center'>В корзине нет товаров</div>")
        $(".gotopay").addClass('d-none');
        // $('.showbasket').addClass('d-none');
      } else {
        $(".gotopay").removeClass('d-none');
      }

      calcItogo(newbasketarray);
      calcItogoOrder(newbasketarray);

    }
  })



  var basket_category = $('.breadcrumbs').attr('category');
  var basketstorage = localStorage.getItem(basket_category)
  if (basketstorage != null) {
    basketarray = JSON.parse(basketstorage);
    var sendarray = [];

    basketarray.forEach(function(item) {
      sendarray.push(parseInt(item.tovar))
    });

    $.post("/basketlist/", {
      tovarsids: JSON.stringify(sendarray),
      basket_category: $('.breadcrumbs').attr('category')
    }, function() {
      if (sendarray.length != 0) {
        basketarray.forEach(function(item) {
          $('input.count41[tovar-id="' + item.tovar + '"]').val(item.count);
        });
      }
    });
  }

  // Если это страница заказа
  if ($('.order-tovars').length > 0) {

    // Если корзина не пустая
    if (basketstorage != null) {
      basketarray = JSON.parse(basketstorage);
      var sendarray = [];

      basketarray.forEach(function(item) {
        sendarray.push(parseInt(item.tovar))
      });

      $(".order-tovars").load("/orderlist/", {
        tovarsids: JSON.stringify(sendarray),
        basket_category: $('.breadcrumbs').attr('category')
      }, function() {

        if (sendarray.length == 0) {
          $(".order-tovars").html("<div class='mt-5 mb-5 h3 text-center'>В корзине нет товаров</div>")
          // $(".gotopay").addClass('d-none');
        } else {
          // $(".gotopay").removeClass('d-none');

          basketarray.forEach(function(item) {
            $('span.count41[tovar-id="' + item.tovar + '"]').html(item.count);
          });
          calcItogoOrder(basketarray);
        }
      });

    } else {
      $(".order").html("<div class='mt-5 pt-5 pb-5 mb-5 h3 text-center'>В корзине нет товаров</div>")
    }

  }




  // при нажатии на кнопка показать содержимое корзины
  $('.showbasket').click(function() {
    var basket_category = $('.breadcrumbs').attr('category');
    var basketstorage = localStorage.getItem(basket_category)

    if (basketstorage != null) {
      basketarray = JSON.parse(basketstorage);
      var sendarray = [];

      basketarray.forEach(function(item) {
        sendarray.push(parseInt(item.tovar))
      });

      $("#modal-body-basket .modal-body").load("/basketlist/", {
        tovarsids: JSON.stringify(sendarray),
        basket_category: $('.breadcrumbs').attr('category')
      }, function() {
        if (sendarray.length == 0) {
          $("#modal-body-basket .modal-body").html("<div class='mt-5 mb-5 h3 text-center'>В корзине нет товаров</div>")
          $(".gotopay").addClass('d-none');
        } else {
          $(".gotopay").removeClass('d-none');
          basketarray.forEach(function(item) {
            $('input.count41[tovar-id="' + item.tovar + '"]').val(item.count);
          });
          calcItogo(basketarray);
        }
      });

    } else {
      $("#modal-body-basket .modal-body").html("<div class='mt-5 mb-5 h3 text-center'>В корзине нет товаров</div>")
    }
  })

  // Кнопка корзина, выводим в нее колво товаров и блокируем кнопки уже добавленных товаров
  var basket_category = $('.breadcrumbs').attr('category');
  var basketstorage = localStorage.getItem(basket_category)
  if (basketstorage != null) {
    var basketarray = JSON.parse(basketstorage);

    // в кнопке корзина не выводим 0 тов.
    $('#basketlen').removeClass('d-none');
    if (basketarray.length == 0) {
      $('#basketlen').addClass('d-none');
    }

    // в кнопке корзина выводим кол-во товаров
    $('#countinbasket').html(basketarray.length);

    basketarray.forEach(function(item) {
      $('button.addtobasket[tovar-id="' + item.tovar + '"]').attr('disabled', true);
      $('button.addtobasket[tovar-id="' + item.tovar + '"]').removeClass('btn-primary').removeClass('btn-primary-gradient').addClass('btn-secondary');
      $('button.addtobasket[tovar-id="' + item.tovar + '"]').html('В корзине');
    })
  } else {
    $('#basketlen').addClass('d-none');
  }

  // При нажатии на кнопку добавить в корзину
  $('.addtobasket').click(function() {
    var tovar_id = $(this).attr('tovar-id');
    var basket_category = $('.breadcrumbs').attr('category');
    var basketstorage = localStorage.getItem(basket_category)

    $(this).attr('disabled', true);
    $(this).removeClass('btn-primary').removeClass('btn-primary-gradient').addClass('btn-secondary');
    $(this).html('В корзине');

    var basketstorage = localStorage.getItem(basket_category)

    if ($('input.count41[tovar-id="' + tovar_id + '"]').length) {
      var count = parseInt($('input.count41[tovar-id="' + tovar_id + '"]').val());
    } else {
      var count = 1;
    }

    if (basketstorage == null) {
      var basketarray = [];
      basketarray.push({
        tovar: tovar_id,
        count: count
      })
      var basketjson = JSON.stringify(basketarray);
    } else {
      var basketarray = JSON.parse(basketstorage);
      basketarray.push({
        tovar: tovar_id,
        count: count
      })
      var basketjson = JSON.stringify(basketarray);
    }

    // в кнопке корзина не выводим 0 тов.
    $('#basketlen').removeClass('d-none');

    // в кнопке корзина выводим кол-во товаров
    $('#countinbasket').html(basketarray.length);

    localStorage.setItem(basket_category, basketjson);
  });


  // Кнопка корзина, выводим в нее колво товаров и блокируем кнопки уже добавленных товаров
  var favorites_storage = localStorage.getItem('favorites')
  if (favorites_storage != null) {
    var favorites_array = JSON.parse(favorites_storage);
    favorites_array.forEach(function(item) {
      $('button.addtofavorite[tovar-id="' + item.tovar + '"]').attr('disabled', true);
      $('button.addtofavorite[tovar-id="' + item.tovar + '"]').removeClass('btn-primary').removeClass('btn-primary-gradient').addClass('btn-secondary');
      $('button.addtofavorite[tovar-id="' + item.tovar + '"]').find('span').html('Добавлено');
    })
  }

  $('.addtofavorite').click(function() {
    var tovar_id = $(this).attr('tovar-id');
    $(this).attr('disabled', true);
    $(this).removeClass('btn-primary').removeClass('btn-primary-gradient').addClass('btn-secondary');
    $(this).find('span').html('Добавлено');
    var favorites_storage = localStorage.getItem('favorites')
    if (favorites_storage == null) {
      var favorites_array = [];
      favorites_array.push({
        tovar: tovar_id
      })
      var favorites_json = JSON.stringify(favorites_array);
    } else {
      var favorites_array = JSON.parse(favorites_storage);
      favorites_array.push({
        tovar: tovar_id
      })
      var favorites_json = JSON.stringify(favorites_array);
    }
    localStorage.setItem('favorites', favorites_json);
  });

  let items = document.querySelectorAll('.carousel .carousel-item')

  items.forEach((el) => {
    const minPerSlide = 5
    let next = el.nextElementSibling
    for (var i = 1; i < minPerSlide; i++) {
      if (!next) {
        // wrap carousel by using first child
        next = items[0]
      }
      let cloneChild = next.cloneNode(true)
      el.appendChild(cloneChild.children[0])
      next = next.nextElementSibling
    }
  })

});

// Need jQuery? Install it with "yarn add jquery", then uncomment to import it.
// import $ from 'jquery';

// console.log('Hello Webpack Encore! Edit me in assets/js/app.js');