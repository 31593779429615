$.fn.extend({
  toggleText: function(a, b) {
    return this.text(this.text() == b ? a : b);
  },

  toggleStyle: function(a, b) {
    return this.attr('style', this.attr('style') == b ? a : b);
  }
});

$('input[type=radio][name="switch-regions"]').change(function() {

  $('.current-region').removeClass('active');
  $('label[for=m_' + $(this).val() + ']').addClass('active');
  $('label[for=w_' + $(this).val() + ']').addClass('active');
  // $(this).addClass('active');

  $('.elem_region').addClass('d-none');
  $('.region_' + $(this).val()).removeClass('d-none');

});

$('input[type=radio][name="switch-weeks-months"]').change(function() {

  $('.current-analytics').toggleClass('active');
  $('.analytics-by-weeks').toggleClass('d-none');
  $('.analytics-by-months').toggleClass('d-none');

});


$('.show-more-entries').click(function() {

  var total_rows = parseInt($(this).find('i.total').text()); // 90
  var count_rows = parseInt($(this).find('i.leaved').text()); // 90 70 50 30 10

  var minus = 20;
  if (minus > total_rows) {
    minus = total_rows;
  }
  var open = 1;

  if (count_rows - minus < minus) {
    $(this).find('b').text(count_rows - minus);
  }

  var open_next = total_rows - (count_rows - minus);
  $(this).find('i.leaved').text(count_rows - minus);

  if (count_rows - minus < 1) {
    $(this).find('b').text(total_rows);
    $(this).find('span').toggleText('Показать еще ', 'Скрыть все ');

    if ($('.show-more-entries').hasClass('hide-entries')) {
      $(this).closest('table').find('tr.more-entries').addClass('d-none');
      $(this).find('i.leaved').text(total_rows);
      $(this).find('b').text(minus);
      open = 0;
    }

    $('.show-more-entries').toggleClass('hide-entries');
  }

  if (open == 1) {
    $(this).closest('table').find('tr.more-entries:lt(' + open_next + ')').removeClass('d-none');
  }







  // $(this).find('span').toggleText('Показать еще ', 'Скрыть ');



});